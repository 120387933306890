.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-color: #0000FF;
}

html,body{
  overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');